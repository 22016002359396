<!--
  PACKAGE_NAME : src\pages\esp\auth\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 권한관리
-->
<template>
  <div class="page-auth-management page-sub-box" style="height: 100%">
    <div class="main-container" style="height: 100%">
      <!-- Left Layer Start -->
      <div class="page-left-box" style="height: 100%">
        <!-- 권한 추가 버튼 영역 -->
        <div>
          <div class="head-btn-box01">
            <div class="head-btn-left">
              <DxButton
                text="권한 추가"
                class="btn_XS default filled add1"
                :height="30"
                @click="handleModal(modal.props.ModalAddAuth.modalName)"
              />
              <DxButton text="삭제" class="btn_XS white light_filled trash" :height="30" @click="deleteAuth" />
            </div>
          </div>
        </div>
        <!-- 권한 추가 버튼 영역 -->

        <!-- 권한 트리 -->
        <div class="left-comp-box" style="height: calc(50% - 53px)">
          <div class="fl per20 h-full" style="height: 100%">
            <DxTreeList
              id="auth"
              :ref="authTreeList.ref"
              :data-source="authTreeList.dataSource"
              key-expr="id"
              :selectedRowKeys="authTreeList.selectedRowKeys"
              :columns="authTreeList.columns"
              :show-column-headers="false"
              :show-column-lines="true"
              :show-borders="true"
              :column-auto-width="true"
              :auto-expand-all="true"
              :no-data-text="noDataText(authTreeList.dataSource.length)"
              @selection-changed="onSelectedTreeRow"
              @cellPrepared="onCellPrepared"
              width="350"
              :height="'100%'"
            >
              <DxSorting mode="single" />
              <DxSelection mode="single" />
              <DxLoadPanel :enabled="true" />
            </DxTreeList>
          </div>
        </div>
        <!-- 권한 트리 -->

        <!-- 권한 사용자 추가 버튼 영역 -->
        <div>
          <div class="head-btn-box01">
            <div class="head-btn-left">
              <DxButton text="사용자 추가" class="btn_XS default filled add1" :height="30" @click="openAddMemberModal" />
              <DxButton text="삭제" class="btn_XS white light_filled trash" :height="30" @click="deleteMember" />
            </div>
          </div>
        </div>
        <!-- 권한 추가 버튼 영역 -->

        <!-- 사용자 트리 -->
        <div class="left-comp-box" style="height: calc(50% - 53px)">
          <div class="fl per20 h-full" style="height: 100%">
            <DxTreeList
              :ref="authMemberList.ref"
              :data-source="filteredMemberSource"
              key-expr="id"
              :columns="authMemberList.columns"
              :selectedRowKeys="authMemberList.selectedRowKeys"
              :show-column-headers="true"
              :show-column-lines="true"
              :show-borders="true"
              :column-auto-width="true"
              width="350"
              :height="'100%'"
              no-data-text="권한 사용자가 없습니다."
              @selection-changed="onSelectionChangedMemberRow"
            >
              <DxFilterRow :visible="true" />
              <DxSelection :recursive="false" mode="multiple" />
            </DxTreeList>
          </div>
        </div>
        <!-- 사용자 트리 -->
      </div>
      <!-- Left Layer End -->

      <!-- Right Layer Start -->
      <div class="page-right-box layout-cut-rio clearfix detail-ui fr">
        <template v-if="authTreeList.isClickTree">
          <div>
            <table class="table_form line-bin" style="height: 154px">
              <caption>
                <strong>권한정보</strong>
              </caption>
              <colgroup>
                <col style="width: 130px" />
                <col style="width: auto" />
              </colgroup>

              <thead class="sub_title_txt">
                <tr>
                  <td colspan="2"><h2>권한정보</h2></td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="row">
                    <label>권한명 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td colspan="1">
                    <DxTextBox
                      v-model="authInfo.authNm"
                      :stylingMode="stylingMode"
                      class="mar_ri10 alB"
                      :max-length="20"
                      placeholder="입력해주세요."
                    >
                      <DxValidator validation-group="validationGroupName">
                        <DxRequiredRule message="권한명을 입력해주세요." />
                      </DxValidator>
                    </DxTextBox>
                  </td>
                </tr>
                <!--                  <tr v-if="getUseAuthDetailSetting">-->
                <!--                    <th scope="row"><label>세부권한 설정 여부</label></th>-->
                <!--                    <td>-->
                <!--                      <div>-->
                <!--                        <DxCheckBox v-model="authInfo.reportFl" text="검색 대상 권한" class="check-type col mar_le15" />-->
                <!--                        <DxCheckBox v-model="authInfo.dataFl" text="소속 데이터 권한" class="check-type col mar_le15" />-->
                <!--                      </div>-->
                <!--                    </td>-->
                <!--                  </tr>-->
                <tr>
                  <th scope="row"><label>사이트/센터 권한</label></th>
                  <td colspan="1">
                    <DxDropDownBox
                      ref="siteDropDown"
                      v-model="authGroupData.selectedIds['site']"
                      :data-source="authGroupData.defaultDataList.site"
                      display-expr="siteNm"
                      value-expr="id"
                      placeholder="사이트 선택"
                      :defer-rendering="false"
                      :show-clear-button="true"
                      :styling-mode="stylingMode"
                      height="30px"
                      class="mar_ri10"
                    >
                      <template #content="{}">
                        <DxDataGrid
                          ref="siteGrid"
                          :data-source="authGroupData.defaultDataList.site"
                          :selected-row-keys="authGroupData.selectedDataList.site"
                          :hover-state-enabled="true"
                          @selection-changed="selectionChangedSite"
                        >
                          <DxColumn caption="사이트" data-field="siteNm" alignment="center" />
                          <DxSelectionGrid mode="multiple" show-check-boxes-mode="always" />
                          <DxPaging :enabled="false" :page-size="10" />
                          <DxScrolling mode="virtual" />
                          <DxFilterRow :visible="false" />
                        </DxDataGrid>
                      </template>
                    </DxDropDownBox>
                  </td>
                  <td>
                    <DxDropDownBox
                      ref="siteDropDown"
                      v-model="authGroupData.selectedIds['tenant']"
                      :data-source="authGroupData.defaultDataList.tenant"
                      display-expr="tenantNm"
                      value-expr="id"
                      placeholder="센터 선택"
                      :defer-rendering="false"
                      :show-clear-button="true"
                      :styling-mode="stylingMode"
                      height="30px"
                      class="mar_ri10"
                    >
                      <template #content="{}">
                        <DxDataGrid
                          ref="tenantGrid"
                          :data-source="authGroupData.defaultDataList.tenant"
                          :selected-row-keys="authGroupData.selectedDataList.tenant"
                          :hover-state-enabled="true"
                          @selection-changed="selectionChangedTenant"
                        >
                          <DxColumn caption="센터" data-field="tenantNm" alignment="center" />
                          <DxSelectionGrid mode="multiple" show-check-boxes-mode="always" />
                          <DxPaging :enabled="false" :page-size="10" />
                          <DxScrolling mode="virtual" />
                          <DxFilterRow :visible="false" />
                        </DxDataGrid>
                      </template>
                    </DxDropDownBox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tabs-container" style="height: calc(100% - 32px)">
            <Tabs id="tabs" ref="tabs" :tabType="2" tab-css-class="tabInnerClass" @selectedIndex="tabSelectedIndex" style="height: 100%">
              <Tab title="메뉴 권한">
                <DxTreeList
                  :ref="authMenuList.ref"
                  :data-source="authMenuList.dataSource"
                  :columns="authMenuList.columns"
                  key-expr="id"
                  parent-id-expr="parentId"
                  :show-column-headers="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :show-borders="true"
                  :column-auto-width="true"
                  :no-data-text="noDataText(authMenuList.dataSource.length)"
                  showBorders
                  @selection-changed="handleAuthMenuTreeSelectionChanged"
                  @row-expanded="activateCommonAuthButton"
                  @row-collapsed="activateCommonAuthButton"
                  @editor-preparing="overrideOnValueChanged"
                  width="100%"
                  :height="'calc(100% - 186px)'"
                >
                  <DxSelection :recursive="true" mode="multiple" />
                  <DxFilterRow :visible="true" />
                  <DxSorting mode="none" />
                </DxTreeList>
              </Tab>
              <Tab title="검색 대상 권한" v-if="authInfo.reportFl">
                <div class="tab-content" style="height: 100%">
                  <div
                    style="display: flex"
                    class="unit-list mar_to5 channel-acco-list-box"
                    v-for="authGroupKey in Object.keys(authGroupDataGrids)"
                    :key="authGroupKey"
                  >
                    <SearchDataGrid
                      v-for="authGroupDataGrid in authGroupDataGrids[authGroupKey].props"
                      :key="authGroupDataGrid.dataField"
                      :data-grid="authGroupDataGrid"
                      :data-source="authGroupData.dataSources[authGroupDataGrid.dataField]"
                      :target="authGroupData.selectedDataList"
                      :total-count="
                        authGroupData.dataSources[authGroupDataGrid.dataField] !== undefined
                          ? authGroupData.dataSources[authGroupDataGrid.dataField].length
                          : 0
                      "
                      @childEvent="onSelectedSearchRow"
                      @RowPreparedEvent="onChangedGroupCheckBox"
                      :height="'calc(100% - 186px)'"
                    />
                  </div>
                </div>
              </Tab>
              <!-- FIXME : 백단 구현전까지 숨김처리 -->
              <!--                <Tab title="소속 데이터 권한" v-if="authInfo.dataFl">-->
              <!--                  <DxTreeList-->
              <!--                    id="dataAuth"-->
              <!--                    :ref="authDeptList.ref"-->
              <!--                    :data-source="authDeptList.dataSource"-->
              <!--                    :filter-value="authDeptList.filterValue"-->
              <!--                    :columns="authDeptList.columns"-->
              <!--                    key-expr="id"-->
              <!--                    parent-id-expr="parentId"-->
              <!--                    :show-column-headers="true"-->
              <!--                    :show-column-lines="true"-->
              <!--                    :show-row-lines="true"-->
              <!--                    :show-borders="false"-->
              <!--                    :column-auto-width="true"-->
              <!--                    :auto-expand-all="true"-->
              <!--                    :no-data-text="noDataText(authDeptList.dataSource.length)"-->
              <!--                    height="700px"-->
              <!--                  >-->
              <!--                    <DxFilterRow :visible="true" />-->
              <!--                    <DxSorting mode="none" />-->
              <!--                  </DxTreeList>-->
              <!--                </Tab>-->
            </Tabs>
          </div>
        </template>
        <template v-else>
          <div class="noformdata">권한을 먼저 선택하세요.</div>
        </template>
      </div>

      <section class="terms bottom-btn-box">
        <div class="page-sub-box">
          <h2 class="hidden">일반 버튼</h2>
          <div class="bottom-btn-wrap">
            <DxButton
              text="저장"
              :width="120"
              :height="40"
              class="default filled txt_S medium"
              validation-group="validationGroupName"
              type="default"
              @click="onSaveFormData"
            />
            <DxButton text="취소" :width="120" :height="40" class="white filled txt_S medium" type="normal" @click="cancelTreeCheck" />
          </div>
        </div>
      </section>
      <!-- Right Layer End -->

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="false"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData"></component>
        </template>

        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.hasOwnProperty('save')
                : !modal.initData.buttons.hasOwnProperty('save')
              : false
          "
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.text
                : ''
              : '',
            useSubmitBehavior: true,
            validationGroup: 'modalValidationGroup',
            width: '120',
            height: '40',
            onClick: e => onConfirmModal(e, modal.sendData),
          }"
        />

        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: () => isOpenModal(false),
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>
<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxColumn, DxFilterRow, DxScrolling, DxSearchPanel, DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxDataGrid, DxGrouping, DxLoadPanel, DxPaging, DxSelection as DxSelectionGrid, DxSorting } from 'devextreme-vue/data-grid';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTagBox } from 'devextreme-vue/tag-box';
  import DxSwitch from 'devextreme-vue/switch';
  import ModalAddAuth from '@/pages/esp/auth/modal-add-auth.vue';
  import ModalAuthCode from '@/pages/esp/auth/modal-auth-code.vue';
  import ModalAddMember from '@/pages/esp/auth/modal-add-member.vue';

  import { cloneObj, findParentId, isSuccess } from '@/plugins/common-lib';

  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';

  import SearchDataGrid from './search-data-grid.vue';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';

  export default {
    components: {
      DxLoadPanel,
      DxDropDownBox,
      DxPaging,
      DxTagBox,
      DxRequiredRule,
      DxValidator,
      DxGrouping,
      DxToolbarItem,
      DxPopup,
      DxSorting,
      DxScrolling,
      DxColumn,
      Tab,
      Tabs,
      DxSelectBox,
      DxSwitch,
      DxTextBox,
      DxFilterRow,
      DxSelection,
      DxSearchPanel,
      DxTreeList,
      DxSelectionGrid,
      DxCheckBox,
      DxButton,
      DxDataGrid,
      ModalAddAuth,
      ModalAuthCode,
      ModalAddMember,
      SearchDataGrid,
    },
    data() {
      return {
        stylingMode: 'outlined',
        authTreeList: {
          // 권한 사용자 트리 리스트 prop
          ref: 'authTreeList',
          isClickTree: false, // 권한 선택 유무
          clickedRowData: '', // 권한 선택 데이터
          dataSource: [], // 권한 데이터
          selectedRowKeys: [], // 선택된 권한 키
          columns: [
            { dataField: 'id', caption: 'id', visible: false },
            { dataField: 'parentId', caption: 'parentId', visible: false },
            { dataField: 'authNm', caption: '권한', allowSorting: false },
          ],
        },
        authMemberList: {
          // 권한 사용자 리스트 prop
          ref: 'authMemberList',
          dataSource: [],
          columns: [{ dataField: 'name', caption: '이름', allowFiltering: true }],
          selectedRowKeys: [],
        },
        authMenuList: {
          // 권한 메뉴 리스트 prop
          ref: 'authMenuList',
          recursive: false, // 트리 재귀 선택 기능 활성화
          dataSource: [],
          columns: [
            { dataField: 'id', caption: 'id', visible: false },
            { dataField: 'parentId', caption: 'parentId', visible: false },
            { dataField: 'menuNm', caption: '메뉴', width: 400 },
            {
              caption: '버튼 권한',
              alignment: 'left',
              cellTemplate: (container, options) => {
                if (options.data.permissions === undefined) return;

                const permissions = options.data.permissions;

                for (const code of Object.keys(permissions)) {
                  const checkbox = new DxCheckBox({
                    ref: `commonAuthCheckbox${options.data.id}${code}`,
                    propsData: {
                      value: permissions[code].value,
                      text: permissions[code].name,
                      elementAttr: {
                        class: `check-type col mar_le10 commonAuthCheckbox commonAuth${options.data.id}${code}`,
                      },
                      onValueChanged: e => this.setPermissionToCommonAuthList(options, permissions, code, e.value),
                    },
                  }).$mount();

                  container.appendChild(checkbox.$el);
                }
              },
            },
            {
              caption: '코드',
              alignment: 'center',
              width: 100,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '관리',
                    elementAttr: {
                      class: 'btn_XS white light_filled',
                    },
                    height: 30,
                    onClick: () => {
                      this.handleModal(this.modal.props.ModalAuthCode.modalName, options);
                    },
                  },
                }).$mount();
                container.appendChild(button.$el);
              },
            },
          ],
        },
        componentPermissionList: {
          // 메뉴별 컴포넌트 권한 prop
          ref: 'componentPermissionList',
          dataSource: [],
          selectedRowKeys: [],
        },
        authDeptList: {
          // TODO : 백단 구현전까지 숨김처리됨
          // 권한 부서 리스트 prop
          ref: 'authDeptList',
          dataSource: [],
          filterValue: [],
          selectedRowKeys: [],
          columns: [
            { dataField: 'id', caption: 'id', visible: false },
            { dataField: 'parentId', caption: 'parentId', visible: false },
            { dataField: 'menuNm', caption: '메뉴', width: 300 },
            {
              caption: '데이터',
              alignment: 'left',
              cellTemplate: (container, options) => {
                const selectBox = new DxSelectBox({
                  propsData: {
                    items: this.authDataCode,
                    placeholder: '선택',
                    displayExpr: 'codeNm',
                    valueExpr: 'codeValue',
                    value: options.data.dataAuth != null ? options.data.dataAuth : 'total',
                    elementAttr: {
                      class: 'check-type col mar_le15',
                    },
                    width: 220,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      options.data.dataAuth = e.value;
                    },
                  },
                }).$mount();
                container.appendChild(selectBox.$el);
              },
            },
            // TODO: 코드관리 기능 추가시 visible: true로 변경
            {
              caption: '코드',
              alignment: 'center',
              width: 100,
              visible: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '보기',
                    elementAttr: {
                      class: 'btn_XS white light_filled',
                    },
                    height: 30,
                  },
                }).$mount();
                container.appendChild(button.$el);
              },
            },
            { dataField: 'visibleFl', visible: false },
          ],
        },
        authGroupDataGrids: {
          // 검색대상노출 그리드 prop
          ipcc: {
            title: 'IPCC 검색 대상 권한',
            isView: true,
            props: [
              {
                dataSource: [],
                visible: true,
                dataField: 'ibg',
                id: 'ibg',
                ref: 'ibg',
                showBorders: true,
                showColumnHeaders: true,
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: false,
                allowColumnReordering: true,
                noDataText: this.noDataText(),
                rowPrepared: this.onRowPrepared,
                height: 300,
                keyExpr: 'id',
                columns: [
                  {
                    caption: 'id',
                    dataField: 'id',
                    alignment: 'center',
                    visible: false,
                  },
                  {
                    caption: '인입그룹',
                    dataField: 'ibgNm',
                    alignment: 'center',
                    visible: true,
                  },
                ],
                selection: {
                  allowSelectAll: true,
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'always',
                  mode: 'multiple',
                },
                scrolling: {
                  mode: 'standard', // ['infinite', 'standard', 'virtual']
                },
              },
              {
                dataSource: [],
                visible: true,
                dataField: 'skl',
                id: 'skl',
                ref: 'skl',
                showBorders: true,
                showColumnHeaders: true,
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: false,
                allowColumnReordering: true,
                noDataText: this.noDataText(),
                rowPrepared: this.onRowPrepared,
                height: 300,
                keyExpr: 'id',
                columns: [
                  {
                    caption: 'id',
                    dataField: 'id',
                    alignment: 'center',
                    visible: false,
                  },
                  {
                    caption: '상담그룹',
                    dataField: 'sklNm',
                    alignment: 'center',
                    visible: true,
                  },
                ],
                selection: {
                  allowSelectAll: true,
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'always',
                  mode: 'multiple',
                },
                scrolling: {
                  mode: 'standard',
                },
              },
              {
                dataSource: [],
                visible: true,
                dataField: 'agtteam',
                id: 'agtteam',
                ref: 'agtteam',
                showBorders: true,
                showColumnHeaders: true,
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: false,
                allowColumnReordering: true,
                noDataText: this.noDataText(),
                rowPrepared: this.onRowPrepared,
                height: 300,
                keyExpr: 'id',
                columns: [
                  {
                    caption: 'id',
                    dataField: 'id',
                    alignment: 'center',
                    visible: false,
                  },
                  {
                    caption: '팀',
                    dataField: 'agtteamNm',
                    alignment: 'center',
                    visible: true,
                  },
                ],
                selection: {
                  allowSelectAll: true,
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'always',
                  mode: 'multiple',
                },
                scrolling: {
                  mode: 'standard',
                },
              },
              {
                dataSource: [],
                visible: true,
                dataField: 'ivrdnis',
                id: 'dnis',
                ref: 'ivrdnis',
                showBorders: true,
                showColumnHeaders: true,
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: false,
                allowColumnReordering: true,
                noDataText: this.noDataText(),
                rowPrepared: this.onRowPrepared,
                height: 300,
                keyExpr: 'id',
                columns: [
                  {
                    caption: 'id',
                    dataField: 'id',
                    alignment: 'center',
                    visible: false,
                  },
                  {
                    caption: '대표번호',
                    dataField: 'dnisNm',
                    alignment: 'center',
                    visible: true,
                  },
                ],
                selection: {
                  allowSelectAll: true,
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'always',
                  mode: 'multiple',
                },
                scrolling: {
                  mode: 'standard',
                },
              },
            ],
          },
        },
        modal: {
          // 공통 모달 prop
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
          props: {
            ModalAddAuth: {
              modalName: 'ModalAddAuth',
              title: '권한 추가',
              width: 550,
              height: 370,
              buttons: {
                save: { text: '저장' },
                cancel: { text: '취소' },
              },
            },
            ModalAuthCode: {
              modalName: 'ModalAuthCode',
              title: '권한 코드',
              width: 900,
              height: 500,
              buttons: {
                save: { text: '저장' },
                cancel: { text: '취소' },
              },
            },
            ModalAddMember: {
              modalName: 'ModalAddMember',
              title: `권한 사용자 추가`,
              width: '50vw',
              height: 'calc(100vh - 300px)',
              buttons: {
                save: { text: '확인' },
                cancel: { text: '취소' },
              },
            },
          },
        },
        authInfo: {
          // 권한 정보 입력 폼 prop
          id: null,
          authNm: null,
          site: '',
          tenant: '',
          reportFl: false, // 검색대상권한(보고서)
          dataFl: false, // 소속데이터권한(UC)
          authMenuList: [],
        },
        authGroupData: {
          // 검색 대상 권한 데이터 prop
          // 검색대상노출권한 초기 default data
          defaultDataList: {
            site: [],
            tenant: [],
            ibg: [],
            skl: [],
            agtteam: [],
            ivrdnis: [],
            vdn: [],
          },
          dataSources: {
            site: [],
            tenant: [],
            ibg: [],
            skl: [],
            agtteam: [],
            ivrdnis: [],
            vdn: [],
          },
          //선택된 그룹 데이터
          selectedDataList: {
            site: [],
            tenant: [],
            ibg: [],
            skl: [],
            agtteam: [],
            ivrdnis: [],
            vdn: [],
          },
          //선택된 그룹 id를 담은 데이터
          selectedIds: {
            site: [],
            tenant: [],
          },
        },
      };
    },
    computed: {
      authDataCode() {
        return this.$_getCode('auth_menu_data').filter(d => d.delFl === 'N');
      },
      // 사용자데이터 필터링
      filteredMemberSource() {
        if (this.authTreeList.isClickTree) {
          return this.authMemberList.dataSource;
        } else {
          return null;
        }
      },
    },
    methods: {
      authMenuListInstance() {
        return this.$refs?.authMenuList?.instance;
      },
      authDeptListInstance() {
        return this.$refs?.authDeptList?.instance;
      },
      tenantGridInstance() {
        return this.$refs?.tenantGrid?.instance;
      },
      siteGridInstance() {
        return this.$refs?.siteGrid?.instance;
      },
      /**
       * 권한 리스트 조회
       *
       * @return {Promise<void>}
       */
      async selectAuthDataList() {
        const authId = this.$store.getters.getAuthId;
        const payload = {
          actionname: 'AUTH_LIST_SUB',
          data: {
            authId: authId,
          },
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          // FIXME : 백단에서 해당 로직을 처리하도록 변경 필요
          // parentId가 없는 데이터를 최상위로 처리
          const topLevelAuths = res.data.data.filter(item => item.parentId === null);
          if (topLevelAuths.length === 0) {
            // 최상위 데이터가 없을 경우, parentId가 있는 데이터들을 최상위로 변경
            res.data.data.forEach(item => {
              if (item.parentId !== null && item.id === authId) {
                item.parentId = null; // parentId를 null로 변경하여 최상위로 이동
              }
            });
          }
          rtnData = res.data.data;
          this.authTreeList.dataSource = rtnData;
        }
        this.authTreeList.dataSource = rtnData;
      },
      /**
       * 사용자 목록 조회
       *
       * @return {Promise<void>}
       */
      async selectMemberDataList() {
        const payload = {
          actionname: 'MEMBER_LIST_ALL',
          data: { authId: this.authTreeList.clickedRowData.id },
          useErrorPopup: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          res.data.data.forEach(row => {
            row.name = `${row.loginNm}(${row.loginId})`;
          });
          this.authMemberList.dataSource = res.data.data;
        }
      },
      /**
       * 검색 대상 권한 데이터 조회
       *
       * @return {Promise<void>}
       */
      async initAuthGroupDataList() {
        // ex) selectedTenantIds -> "1,2,3,4"
        const selectedTenantIds = this.authGroupData.selectedIds.tenant || null;
        const yesFlag = this.$_enums.common.stringViewFlag.YES.value;
        const apiCalls = {
          groupAllList: this.CALL_API({
            actionname: 'GROUP_LIST_ALL',
            data: {
              authId: this.$store.getters.getAuthId,
            },
          }),
          ibgList: this.CALL_CC_API({
            actionname: 'CC_IBG_LIST',
            data: {
              tenantId: selectedTenantIds,
              sort: '+ibgNm',
              viewFl: yesFlag,
            },
          }),
          sklList: this.CALL_CC_API({
            actionname: 'CC_SKL_LIST',
            data: {
              tenantId: selectedTenantIds,
              sort: '+sklNm',
              viewFl: yesFlag,
            },
          }),
          agtteamList: this.CALL_CC_API({
            actionname: 'CC_AGTTEAM_LIST',
            data: {
              tenantId: selectedTenantIds,
              sort: '+agtteamNm',
              viewFl: yesFlag,
            },
          }),
          ivrDnisList: this.CALL_CC_API({
            actionname: 'CC_IVR_DNIS_LIST',
            data: {
              tenantId: selectedTenantIds,
              viewFl: yesFlag,
            },
          }),
        };

        const resList = await Promise.allSettled(Object.values(apiCalls));
        const results = Object.keys(apiCalls).reduce((acc, key, index) => {
          acc[key] = resList[index];
          return acc;
        }, {});

        // 전체 초기화
        if (results.groupAllList.status === 'fulfilled' && isSuccess(results.groupAllList.value)) {
          this.initProcessGroupData(results);
        }
      },
      /**
       * 검색 대상 권한 초기 데이터 설정
       *
       * @param results
       */
      initProcessGroupData(results) {
        const site = results.groupAllList.value.data.data.site;
        const tenant = results.groupAllList.value.data.data.tenant;
        const ibg = this.fetchDataOrEmptyArray(results.ibgList);
        const skl = this.fetchDataOrEmptyArray(results.sklList);
        const agtteam = this.fetchDataOrEmptyArray(results.agtteamList);
        const ivrdnis = this.fetchDataOrEmptyArray(results.ivrDnisList);
        this.authGroupData.defaultDataList = { site, tenant, ibg, skl, agtteam, ivrdnis };
        this.authGroupData.dataSources = { site, tenant, ibg, skl, agtteam, ivrdnis };
      },
      fetchDataOrEmptyArray(result) {
        return result.status === 'fulfilled' && isSuccess(result.value) ? result.value.data.data : [];
      },
      /**
       * 사용자 그룹 권한 조회
       *
       * @return {Promise<void>}
       */
      async selectGroupDataList() {
        // 초기화
        this.authGroupData.selectedDataList = {
          site: [],
          tenant: [],
          ibg: [],
          skl: [],
          agtteam: [],
          vdn: [],
          ivrdnis: [],
        };

        const payload = {
          actionname: 'AUTH_GROUP_LIST_ALL', // 메뉴그룹별 권한,
          data: {
            authId: this.authTreeList.clickedRowData.id,
          },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          const selectedRows = {};
          let selectedKeywords = [];
          res.data.data.forEach(row => {
            // FIXME : U_CODE 공통코드 의존성 제거 필요 (authGroupCode)
            let keyword = row.authGroupValue.toLowerCase();
            if (!selectedRows[keyword]) {
              selectedRows[keyword] = [];
              selectedKeywords.push(keyword);
            }
            selectedRows[keyword].push(row.groupId);
          });

          for (let key of selectedKeywords) {
            this.authGroupData.selectedDataList[key] = selectedRows[key];
          }

          this.setChangedSite(selectedRows?.site);
          this.setChangedTenant(selectedRows?.tenant);
        }
      },
      /**
       * 모달 팝업 오픈
       *
       * @param props
       * @param sendData
       */
      handleOpenModal(props, sendData) {
        const name = props.modalName;
        sendData.modal = name;
        this.onOpenModal(name, props, sendData);
      },
      /**
       * 모달 팝업 핸들러
       *
       * @param modalName 모달 이름
       * @param params 파라미터
       */
      handleModal(modalName, params = {}) {
        if (modalName === this.modal.props.ModalAddAuth.modalName) {
          this.handleOpenModal(this.modal.props.ModalAddAuth, {
            rowData: this.authTreeList.clickedRowData,
            list: this.authTreeList.dataSource,
          });
        } else if (modalName === this.modal.props.ModalAddMember.modalName) {
          this.handleOpenModal(this.modal.props.ModalAddMember, {
            rowData: this.authTreeList.clickedRowData,
            authList: this.authTreeList.dataSource,
          });
        } else if (modalName === this.modal.props.ModalAuthCode.modalName) {
          let props = this.modal.props.ModalAuthCode;
          props.title = `권한 코드 (${params.data.menuNm})`;

          this.handleOpenModal(props, {
            menuList: this.authMenuList.dataSource,
            id: params.data.id,
            permissions: params.data.permissions,
            permissionList: this.componentPermissionList.dataSource,
          });
        }
      },
      /**
       * 모달 팝업 오픈 여부
       * @param boolean
       */
      isOpenModal(boolean) {
        this.modal.isOpened = boolean;
        if (!boolean) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /**
       * 공통 모달 팝업 오픈
       *
       * @param componentNm
       * @param componentInitData
       * @param sendData
       */
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      /**
       * 모달 팝업 이벤트 처리
       *
       * @param e
       * @param sendData
       * @return {Promise<void>}
       */
      async onConfirmModal(e, sendData) {
        //해당 모달 컴포넌트에서 데이터 저장
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${sendData.modal}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            if (res.res === 200) {
              if (sendData.modal === 'ModalAddAuth') {
                this.isOpenModal(false);
                this.addNewAuth(res);
              } else if (sendData.modal === 'ModalAddMember') {
                this.isOpenModal(false);
                this.addMember(res);
              } else if (sendData.modal === 'ModalAuthCode') {
                this.isOpenModal(false);
                this.addAuthCode(res);
              }
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          })
          .catch(err => {
            console.error(err);
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          });
      },
      /**
       * 신규 권한 추가
       *
       * @param param
       * @return {Promise<void>}
       */
      async addNewAuth(param) {
        const payload = {
          actionname: 'AUTH_LIST_INSERT',
          data: [
            {
              authNm: param.authNm,
              parentId: param.parentId,
              reportFl: this.$_enums.common.stringUsedFlag.YES.value, // FIXME : 변경필요 (임시) 검색대상권한 기본값 Y 설정하였음(원래는 N), 세부 권한 설정 숨기고 나서 수정한 내용.
              dataFl: this.$_enums.common.stringUsedFlag.NO.value,
            },
          ],
          useErrorPopup: true,
        };

        const result = await this.CALL_API(payload);
        if (isSuccess(result)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          const errorMessage = result.message
            ? result.message
            : this.$_msgContents('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' });
          this.$_Toast(errorMessage);
        }
        await this.refreshAuthPage();
      },
      /**
       * 권한 사용자 추가
       *
       * @param res
       */
      addMember(res) {
        const treeVm = this.authTreeList;
        res.selectedRowsData.forEach(row => {
          row.name = `${row.loginNm}(${row.loginId})`;
          row.authId = treeVm.clickedRowData.id;
        });

        this.saveMember(res.selectedRowsData);
      },
      /**
       * 메뉴권한 추가
       *
       * @param resData
       * @return {Promise<void>}
       */
      async addAuthCode(resData) {
        // 일괄 삭제
        if (resData.globalRemoveCodes.length > 0) {
          for (const code of resData.globalRemoveCodes) {
            const target = this.authMenuList.dataSource.filter(item => item.permissions && item.permissions[code.key]);
            if (target === undefined) continue;
            for (const row of target) {
              delete row.permissions[code.key];
            }
          }
        }

        // 동록한 권한코드 적용
        const permissions = {};
        for (const authCode of resData.authCode) {
          permissions[authCode.key] = {
            disabled: false,
            value: authCode.value !== undefined ? authCode.value : false,
            name: authCode.name,
            id: null,
          };

          // 벌크 등록
          if (resData.bulkMenuList && resData.bulkMenuList[authCode.id]) {
            for (const menu of resData.bulkMenuList[authCode.id]) {
              const target = this.authMenuList.dataSource.find(item => item.id === menu);
              if (target === undefined) continue;
              if (!target.permissions) target.permissions = {};
              target.permissions[authCode.key] = cloneObj(permissions[authCode.key]);
            }
          }
        }

        const row = this.authMenuList.dataSource.find(item => item.id === resData.id);
        if (row) row.permissions = permissions;

        await this.authMenuListInstance()?.refresh();
      },
      /**
       * 권한 사용자 저장/업데이트
       *
       * @param dataList
       * @return {Promise<void>}
       */
      async saveMember(dataList) {
        const selectedAuthId = this.authTreeList.clickedRowData.id;
        const payload = {
          actionname: 'MEMBER_LIST_INSERT',
          data: dataList.map(member => {
            return {
              id: member.id,
              authId: member.authId,
              loginId: member.loginId,
            };
          }),
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.authMemberList.dataSource = this.authMemberList.dataSource.concat(dataList);
          await this.refreshAuthPage();
          this.$refs.authTreeList.instance.selectRows([selectedAuthId]); // 권한 트리 선택 이벤트 발생
        }
      },
      /**
       * 권한 사용자 삭제
       *
       * @returns {Promise<void>}
       */
      async deleteMember() {
        const selectedAuthId = this.authTreeList.clickedRowData.id;
        const vm = this.authMemberList;

        const delMemberList = [];
        if (vm.selectedRowKeys.length > 0) {
          vm.selectedRowKeys.forEach(key => {
            const row = vm.dataSource.find(item => item.id === key);
            row.authId = null;
            delMemberList.push(row);
          });
        } else {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const payload = {
          actionname: 'MEMBER_AUTH_DELETE',
          data: { data: delMemberList },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Msg(this.$_msgContents('CMN_SUC_DELETE'));
          this.authMemberList.dataSource = this.authMemberList.dataSource.filter(item => !delMemberList.includes(item));
          await this.refreshAuthPage();
          this.$refs.authTreeList.instance.selectRows([selectedAuthId]); // 권한 트리 선택 이벤트 발생
        }
      },
      /**
       * 권한 사용자 추가 모달 팝업
       */
      openAddMemberModal() {
        if (this.authTreeList.isClickTree && this.authTreeList.clickedRowData != null) {
          this.handleModal(this.modal.props.ModalAddMember.modalName);
        } else {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      },
      /**
       * 권한 삭제
       *
       * @return {Promise<void>}
       */
      async deleteAuth() {
        if (this.authTreeList.isClickTree && this.authTreeList.clickedRowData != null) {
          const authIds = await this.getAllRelatedAuthIds(this.authTreeList.clickedRowData.id);

          // 사용자에게 권한이 부여된 경우 삭제 불가
          if (await this.checkAuthMember(authIds)) {
            this.$_Msg(
              this.$_msgContents('CANNOT_BE_DEL_ACCOUNT_AUTH', { defaultValue: '사용자에게 부여된 권한이 있어 삭제가 불가능합니다.' }),
            );
            return;
          }

          if (
            await this.$_Confirm(
              this.$_msgContents('COMMON.MESSAGE.CONFIRM_DELETE_WITH_SUB_PERMISSIONS', {
                defaultValue: '삭제 시 하위권한까지 일괄 삭제됩니다. <br>삭제하시겠습니까?',
              }),
            )
          ) {
            await this.deleteAuthById(authIds);
          }
        } else {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      },
      /**
       * 현재 권한과 관련된 모든 권한 id들을 반환
       *
       * @param authId
       * @return {Promise<any[]>}
       */
      async getAllRelatedAuthIds(authId) {
        const relatedIds = new Set();
        const queue = [authId]; // 초기 큐에 authId를 추가

        while (queue.length > 0) {
          const currentId = queue.shift(); // 큐에서 현재 id를 꺼냄

          if (relatedIds.has(currentId)) {
            continue; // 이미 처리된 id는 무시
          }

          relatedIds.add(currentId); // 관련된 id Set에 현재 id 추가

          // 현재 id와 관련된 자식 id들을 찾음
          const children = this.authTreeList.dataSource.filter(node => node.parentId === currentId);

          // 자식 id들을 큐에 추가
          for (const child of children) {
            queue.push(child.id);
          }
        }

        return Array.from(relatedIds); // 중복 배제 후 관계된 id들을 배열로 변환하여 반환
      },
      /**
       * 권한 사용자 부여 여부 확인
       *
       * @param authIds {Array} - 권한 id 배열
       * @return {Promise<boolean>}
       */
      async checkAuthMember(authIds) {
        if (authIds.length === 0) return false; // 권한이 없을 경우 false 반환

        const payload = {
          actionname: 'MEMBER_LIST_ALL',
          data: { authId: authIds.join(',') },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          return res.data.data.length > 0;
        }
        return false;
      },
      /**
       * 권한 삭제
       *
       * @param authIds {Array} - 권한 id 배열
       * @return {Promise<void>}
       */
      async deleteAuthById(authIds) {
        const payload = {
          actionname: 'AUTH_LIST_DELETE',
          data: [
            ...authIds.map(id => {
              return { authId: id };
            }),
          ],
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.authTreeList.dataSource = this.authTreeList.dataSource.filter(node => !authIds.includes(node.id));
          this.$_Toast(this.$_msgContents('CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
          await this.refreshAuthPage();
        }
      },
      /** @description : 데이터 없을시 출력 */
      noDataText(length) {
        if (length === 0) {
          return '추가된 권한이 없습니다.';
        }
      },
      /**
       * 메뉴 리스트 설정
       *
       * @return {Promise<void>}
       */
      async setMenuList() {
        const filterByAuthMenu = this.$store.getters.getAuthId === this.authTreeList.clickedRowData.id;
        const payload = {
          actionname: 'MENU_LIST_ALL',
          data: {
            filterByAuthMenu: filterByAuthMenu,
            authId: this.authTreeList.clickedRowData.id,
            viewFl: this.$_enums.common.stringViewFlag.YES.value,
            isCompact: true, // 간략 조회 여부
          },
          useErrorPopup: true,
        };
        let rtnData = [];
        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        this.authMenuList.dataSource = rtnData;
      },
      /**
       * 메뉴별 버튼권한 리스트 설정
       *
       * @return {Promise<void>}
       */
      async setMenuPermissionData() {
        const payload = {
          actionname: 'AUTH_MENU_PERMISSION_LIST_ALL',
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          rtnData = res.data.data;
        }

        for (const row of rtnData) {
          const target = this.authMenuList.dataSource.find(item => item.id === row.menuId);

          if (target === undefined) return;
          if (!target.permissions) target.permissions = {};

          target.permissions[row.permission.funcCode] = {
            name: row.permission.funcName,
            code: row.permission.funcCode,
            id: row.permissionId,
            value: false,
            disabled: false,
          };
        }
      },
      /**
       * 권한 트리 리스트 클릭 이벤트
       *
       * @param e
       */
      async onSelectedTreeRow(e) {
        if (e.selectedRowsData.length <= 0) return;

        const row = { data: e.selectedRowsData[0] };
        this.authTreeList.isClickTree = true;
        this.authTreeList.clickedRowData = row.data;
        this.authInfo.id = row.data.id;
        this.authInfo.authNm = row.data.authNm;
        this.authInfo.reportFl = row.data.reportFl === 'Y';
        this.authInfo.dataFl = row.data.dataFl === 'Y';

        // 메뉴 리스트 조회
        await this.setMenuList();
        // 사용자 리스트 조회
        await this.selectMemberDataList();
        // 메뉴별 권한 조회
        await this.selectMenuDataList();
        // 검색그룹 조회
        await this.selectGroupDataList();
      },
      // 검색대상 Click event
      onSelectedSearchRow(targetSelectedRowKeys, dataField, selectedKeys) {
        targetSelectedRowKeys[dataField] = selectedKeys;
      },
      // grid 그룹 헤더 체크 이벤트
      onChangedGroupCheckBox(e, key, groupData) {
        if (e.value) {
          this.authGroupData.selectedDataList[key] = groupData.items.map(d => d.id);
        } else {
          this.authGroupData.selectedDataList[key] = [];
        }
      },
      // 사용자 리스트 클릭
      onSelectionChangedMemberRow(e) {
        this.authMemberList.selectedRowKeys = e.selectedRowKeys;
      },
      /**
       * 메뉴별 권한 조회
       *
       * @return {Promise<void>}
       */
      async selectMenuDataList() {
        // 메뉴권한 초기화
        for (const node of this.authMenuList.dataSource) {
          // 메뉴권한 - 버튼권한 초기화
          if (node.permissions === undefined) continue;
          for (const permission of Object.keys(node.permissions)) {
            node.permissions[permission].value = false;
            node.permissions[permission].disabled = false;
          }
        }

        // 소속 데이터 권한 초기화
        for (const node of this.authDeptList.dataSource) {
          node.dataAuth = null;
        }

        const authMenuPayload = {
          actionname: 'AUTH_MENU_LIST_ALL',
          data: {
            authId: this.authTreeList.clickedRowData.id,
            sort: '+id',
            isViewMenuType: true, // 메뉴타입 조회
          },
          useErrorPopup: true,
        };

        const authMenuPermissionPayload = {
          actionname: 'AUTH_MENU_PERMISSION_LIST_ALL',
          data: {
            authId: this.authTreeList.clickedRowData.id,
            sort: '+id',
          },
          useErrorPopup: true,
        };

        const authMenuResponse = await this.CALL_API(authMenuPayload);
        const authMenuPermissionResponse = await this.CALL_API(authMenuPermissionPayload);

        try {
          // 첫 번째 API 응답 처리 (메뉴별 권한 데이터)
          if (isSuccess(authMenuResponse)) {
            // 두 번째 API 응답 처리(메뉴별 버튼 권한 데이터)
            const permissionDatas = isSuccess(authMenuPermissionResponse)
              ? this.reduceAuthPermission(authMenuPermissionResponse.data.data)
              : null;

            const authMenuList = this.authMenuList.dataSource;
            this.authMenuListInstance().clearSelection();
            // 부모노드를 선택하면 자식노드도 같이 선택 되므로 자식노드만 선택 하도록 처리
            const selectChildrenNodeIds = authMenuResponse.data.data
              .filter(d => {
                const node = this.authMenuListInstance().getNodeByKey(d.menuId);
                return !(node && node.hasChildren); // 자식 노드가 없는 경우만 선택
              })
              .map(d => d.menuId);
            this.authMenuListInstance().selectRows(selectChildrenNodeIds, false);

            for (const x of authMenuResponse.data.data) {
              if (permissionDatas && permissionDatas.length > 0) {
                // 버튼권한 빈 세팅
                let permissionData =
                  permissionDatas.find(data => data[0].authMenuId === x.id) !== undefined
                    ? permissionDatas.find(data => data[0].authMenuId === x.id)
                    : undefined;

                if (permissionData) {
                  const target = authMenuList.find(d => d.id === x.menuId);
                  if (target === undefined) continue;
                  if (!target.permissions) target.permissions = {};

                  for (const permission of permissionData) {
                    target.permissions[permission.permission.funcCode] = {
                      name: permission.permission.funcName,
                      code: permission.permission.funcCode,
                      value: permission.useFl === 'Y',
                      disabled: false,
                    };
                  }
                }
              }
            }
          }
        } catch (error) {
          // 오류 처리
          console.error(error);
        }
      },
      // 버튼권한 menuId 별 reduce
      reduceAuthPermission(data) {
        let resultArray;
        try {
          // id를 기준으로 그룹화하기 위한 객체 생성
          const groupedData = data.reduce((result, item) => {
            const key = item.menuId;

            // 그룹이 존재하지 않으면 새로운 그룹을 생성
            if (!result[key]) {
              result[key] = [];
            }

            // 항목을 해당 그룹에 추가
            result[key].push(item);

            return result;
          }, {});

          // 그룹화된 데이터를 배열로 변환
          resultArray = Object.values(groupedData);
        } catch (e) {
          console.error(e);
        }

        return resultArray;
      },
      /**
       * 권한 저장 이벤트
       *
       * @param e
       * @param isDuplicate
       * @return {Promise<boolean>}
       */
      async onSaveFormData(e, isDuplicate) {
        if (this.authTreeList.clickedRowData == null || this.authTreeList.clickedRowData === '') {
          this.$_Toast('권한을 먼저 선택해주세요.');
          return false;
        }

        if (!e.validationGroup.validate().isValid) {
          if (this.authInfo.authNm == null || this.authInfo.authNm === '') {
            this.$_Toast('권한명을 입력해주세요.');
            return false;
          }
          return false;
        }

        const selectedAuthId = this.authTreeList.clickedRowData.id;
        const auth = [
          {
            id: selectedAuthId,
            authNm: this.authInfo.authNm,
            reportFl: this.authInfo.reportFl === true ? 'Y' : 'N',
            dataFl: this.authInfo.dataFl === true ? 'Y' : 'N',
            ...(this.authTreeList.clickedRowData.parentId !== null && { parentId: this.authTreeList.clickedRowData.parentId }),
          },
        ];

        // authMenu 배열 초기화
        let authMenu = [];

        // 기존 선택된 메뉴 ID를 복제
        const selectedMenuId = [...cloneObj(this.getSelectedAuthMenuList())].map(d => d.id);

        // 유효성 체크 및 메시지 설정
        let confirmMessage = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
        if (selectedMenuId.length === 0) confirmMessage = '선택된 메뉴가 없습니다. 저장하시겠습니까?';

        // 선택된 메뉴의 부모 ID를 찾아서 parentMenuIds 배열에 추가
        const parentMenuIds = [];
        selectedMenuId.forEach(id => {
          parentMenuIds.push(...findParentId(this.authMenuList.dataSource, id));
        });

        // 중복된 ID 제거 및 정렬
        const uniqueSelectedMenuId = Array.from(new Set([...selectedMenuId, ...parentMenuIds])).sort((a, b) => a - b);

        // authMenu 배열에 객체 추가
        uniqueSelectedMenuId.forEach(id => {
          authMenu.push({
            menuId: id,
            authId: selectedAuthId,
          });
        });

        const authGroup = [];
        Object.keys(this.authGroupData.selectedDataList).forEach(key => {
          if (this.authGroupData.selectedDataList[key].length > 0) {
            this.authGroupData.selectedDataList[key].forEach(id => {
              // FIXME : U_CODE 공통코드 의존성 제거 필요 (authGroupCode)
              authGroup.push({
                authId: selectedAuthId,
                authGroupCd: this.getAuthGroup(key).id,
                groupId: typeof id === 'object' ? id.id : id,
              });
            });
          }
        });

        const permissions = [];
        this.getSelectedAuthMenuList().forEach(row => {
          if (row.permissions !== undefined) {
            for (const key of Object.keys(row.permissions)) {
              permissions.push({
                authId: selectedAuthId,
                menuId: row.id,
                permissionId: row.permissions[key].id,
                funcCode: row.permissions[key].code,
                funcName: row.permissions[key].name,
              });
            }
          }
        });

        const authData = [];
        this.authDeptList.dataSource.forEach(row => {
          if (row.dataAuth != null) {
            authData.push({
              authId: selectedAuthId,
              menuId: row.id,
              dataAuth: row.dataAuth,
            });
          }
        });

        if (isDuplicate || (await this.$_Confirm(confirmMessage))) {
          const payload = {
            actionname: 'AUTH_LIST_INSERT_ALL',
            data: {
              auth: auth,
              menu: authMenu,
              group: authGroup,
              permissions: permissions,
              authData: authData,
            },
            loading: true,
            useErrorPopup: true,
          };
          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          }
          await this.refreshAuthPage(); // 권한 페이지 새로고침
          this.$refs.authTreeList.instance.selectRows([selectedAuthId]); // 권한 트리 선택 이벤트 발생
        }
      },
      // 권한 선택 해제 후 초기화
      cancelTreeCheck() {
        this.authTreeList.selectedRowKeys = [];
        this.authTreeList.isClickTree = false;
        this.authTreeList.clickedRowData = null;
        this.authInfo.authNm = '';
        this.authInfo.reportFl = false;
        this.authInfo.dataFl = false;
      },
      /** @description : 트리 헤더 재설정 */
      onCellPrepared(cellInfo) {
        let element = cellInfo.cellElement;
        if (cellInfo.rowType === 'header') {
          element.style.textAlign = 'center';
          if (cellInfo.columnIndex === 0) {
            element.setAttribute('colspan', 2);
          } else {
            element.style.display = 'none';
          }
        }
      },
      /**
       * 검색 대상 권한 그룹 보이기 여부
       *
       * @param configType
       */
      showAuthGroupGrid(configType) {
        const authGroupIds = Object.keys(this.authGroupDataGrids);
        authGroupIds.forEach(key => {
          this.authGroupDataGrids[key].isView = false;
        });
        this.authGroupDataGrids[configType].isView = true;
      },
      // 메뉴권한의 권한 세팅
      setPermissionToCommonAuthList(options, permissions, key, value) {
        const target = this.getSelectedAuthMenuList().find(x => x.id === options.data.id);
        if (target !== undefined) {
          target.permissions = target.permissions !== undefined ? target.permissions : permissions;
          target.permissions[key].value = value;
        }
      },
      /**
       * 메뉴권한 트리 선택 변경 이벤트
       *
       * @param e
       */
      handleAuthMenuTreeSelectionChanged(e) {
        const selectRowKeys = [];
        e.component.getSelectedRowsData('all').forEach(rowData => {
          selectRowKeys.push(rowData.id);
        });
        this.authMenuListInstance().selectRows(selectRowKeys, true);
        this.activateCommonAuthButton();
      },
      // 메뉴권한 > 버튼메뉴 활성화 토글
      activateCommonAuthButton() {
        // 버튼 초기화
        document.querySelectorAll('.commonAuthCheckbox').forEach(checkbox => {
          checkbox.classList.add('dx-state-disabled');
        });

        // 선택 버튼 활성화
        this.getSelectedAuthMenuList().forEach(node => {
          if (node.permissions === undefined) return;

          Object.keys(node.permissions).forEach(key => {
            const checkbox = document.querySelector(`.commonAuth${node.menuId !== undefined ? node.menuId : node.id}${key}`);
            if (!node.permissions[key].disabled && checkbox !== null) {
              checkbox.classList.remove('dx-state-disabled');
            }
          });
        });
      },

      overrideOnValueChanged(e) {
        if (e.parentType === 'dataRow' && e.row.data.disabled) {
          e.editorOptions.disabled = e.row.data.disabled;
          e.editorOptions.onValueChanged = e => {
            e.component.option('value', false);
            e.element.parentElement.parentElement.parentElement.classList.remove('dx-selection');
          };
        }
      },

      // 트리데이터에서 선택된 하위 권한까지 가져오기
      getAllSelectedKeys(selectedKeys, data) {
        const allSelectedKeys = new Set(selectedKeys);

        selectedKeys.forEach(key => {
          const item = data.find(d => d.parentId === key);
          if (item && item.children) {
            const childrenKeys = this.getAllSelectedKeys(
              item.children.map(child => child.key),
              item.children,
            );
            childrenKeys.forEach(childKey => allSelectedKeys.add(childKey));
          }
        });
      },
      // 사이트 로우 선택시 이벤트
      selectionChangedSite(e) {
        if (e.selectedRowKeys.length === 0) {
          return; // 빈 리스트일 때는 아무런 작업도 하지 않음
        }
        const selectedIds = e.selectedRowKeys.map(d => d.id);
        this.setChangedSite(selectedIds);
      },
      /**
       * 사이트 선택 셀렉트 박스 번경 이벤트
       *
       * @param selectedIds
       */
      setChangedSite(selectedIds) {
        if (selectedIds === null || selectedIds === undefined || selectedIds?.length === 0) {
          this.authGroupData.selectedIds.site = [];
          this.authGroupData.selectedDataList.site = [];
          this.authGroupData.selectedIds.tenant = [];
          this.authGroupData.selectedDataList.tenant = [];
          return;
        }
        this.authGroupData.selectedIds.site = selectedIds;
        this.authGroupData.selectedDataList.site = this.authGroupData.defaultDataList.site.filter(d => selectedIds.includes(d.id));
        this.authGroupData.selectedDataList.tenant = this.authGroupData.defaultDataList.tenant.filter(d => selectedIds.includes(d.siteId));
        this.siteGridInstance().refresh();
      },
      // tenant 로우 선택시 이벤트
      selectionChangedTenant(e) {
        const selectedIds = e.selectedRowKeys.map(d => d.id);
        this.setChangedTenant(selectedIds);
      },
      /**
       * 센터 선택 셀렉트박스 변경 이벤트
       *
       * @param selectedIds
       */
      setChangedTenant(selectedIds) {
        if (selectedIds === null || selectedIds === undefined || selectedIds?.length === 0) {
          this.authGroupData.selectedIds.tenant = [];
          this.authGroupData.selectedDataList.tenant = [];
          this.authGroupData.dataSources.ibg = [];
          this.authGroupData.dataSources.skl = [];
          this.authGroupData.dataSources.agtteam = [];
          this.authGroupData.dataSources.ivrdnis = [];
          this.tenantGridInstance().refresh();
          return;
        }

        this.authGroupData.selectedIds.tenant = selectedIds;
        this.authGroupData.selectedDataList.tenant = this.authGroupData.defaultDataList.tenant.filter(d => selectedIds.includes(d.id));
        this.authGroupData.dataSources.ibg = this.authGroupData.defaultDataList.ibg.filter(d => selectedIds.includes(d.tenantId));
        this.authGroupData.dataSources.skl = this.authGroupData.defaultDataList.skl.filter(d => selectedIds.includes(d.tenantId));
        this.authGroupData.dataSources.agtteam = this.authGroupData.defaultDataList.agtteam.filter(d => selectedIds.includes(d.tenantId));
        this.authGroupData.dataSources.ivrdnis = this.authGroupData.defaultDataList.ivrdnis.filter(d => selectedIds.includes(d.tenantId));
        this.tenantGridInstance().refresh();
      },
      /**
       * authGroup 정보에서 해당 코드값에 맞는 데이터 가져오기
       *
       * @param groupKey
       * @return {{CODE_VALUE: string, VALUE: number, CODE_NM: string} | {CODE_VALUE: string, VALUE: number, CODE_NM: string} | {CODE_VALUE: string, VALUE: number, CODE_NM: string} | {CODE_VALUE: string, VALUE: number, CODE_NM: string} | {CODE_VALUE: string, VALUE: number, CODE_NM: string}}
       */
      getAuthGroup(groupKey) {
        return this.$_getCode('auth_group').find(d => d.codeValue === groupKey.toUpperCase());
      },
      /**
       * 탭 클릭 이벤트
       *
       * @param index
       */
      tabSelectedIndex(index) {
        switch (index) {
          case 0:
            this.authMenuListInstance()?.refresh();
            break;
          case 1:
            this.showAuthGroupGrid('ipcc');
            break;
          case 2:
            this.authDeptListInstance?.refresh();
            break;
          default:
            break;
        }
      },
      /**
       * 권한 페이지 새로고침
       *
       * @return {Promise<void>}
       */
      async refreshAuthPage() {
        await this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
        await this.$_setStoreSiteTenant(); //사이트,센터 store 재설정
        await this.cancelTreeCheck();
        await this.selectAuthDataList();
      },
      /**
       * 메뉴 트리 리스트 선택된 row 데이터 반환
       *
       * @returns {*}
       */
      getSelectedAuthMenuList() {
        return this.authMenuListInstance().getSelectedRowsData();
      },
    },
    async created() {
      // 권한 설정
      await this.selectAuthDataList();
      // 검색대상데이터 세팅
      await this.initAuthGroupDataList();
      // 메뉴별 버튼 권한
      await this.setMenuPermissionData();
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep .bigtabBox.on {
    height: 100%;
  }

  ::v-deep .fr_wrap {
    height: 100%;
    margin-top: 20px !important;
    margin-left: 10px;
  }

  .page-auth-management {
    display: flex;
    flex-direction: column;
    height: 100%; /* 전체 높이를 100%로 설정 */
  }

  .main-container {
    display: flex;
    flex: 1;
  }

  .page-left-box,
  .page-right-box {
    height: 100%; /* 부모 요소에 맞춰 100% 높이 설정 */
  }

  .page-right-box {
    flex: 3 1 auto; /* 남은 공간의 3부분을 차지하도록 설정 */
  }

  .page-auth-management .menuitemshow:not(.left-show) .bottom-btn-box {
    width: calc(100% - 222px);
  }

  .page-auth-management .menuitemshow.left-show .bottom-btn-box {
    width: calc(100% - 322px);
  }

  .page-auth-management .channel-acco-list-box .result-list {
    padding-bottom: 0;
  }

  .page-auth-management .slide-enter-active,
  .slide-leave-active {
    transition: all 800ms;
  }

  .page-auth-management .slide-enter-to,
  .page-auth-management .slide-leave {
    max-height: 500px;
    overflow: hidden;
  }

  .page-auth-management .slide-enter,
  .page-auth-management .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .page-auth-management .noformdata {
    height: 20vh;
    line-height: 80vh;
    text-align: center;
  }

  .page-auth-management .ui-glid-box > div.fl,
  .ui-glid-box > div.fr {
    border-right: none;
  }

  .page-auth-management .table_form td > div {
    display: inline-block;
    vertical-align: middle;
  }

  .page-auth-management td {
    white-space: normal;
  }

  .left-comp-box {
    height: 33vh; /* 전체 높이를 맞추기 위해 설정 */
    padding-right: 10px;
  }

  .detail-ui .sub_title_txt tr td {
    padding: 15px 4px 13px 10px;
  }

  ::v-deep .tabInnerClass {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .tabs-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .tab-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 496px;
    overflow-y: auto;
  }

  .page-auth-management .table_form td .empty-box {
    width: 10px;
  }
</style>
